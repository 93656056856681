import { createApp } from 'vue'
import App from './App.vue'
import axios from './util/require'
import router from './routes/Router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import mitt from 'mitt'

const app = createApp(App)

app.config.globalProperties.$http = axios
app.config.globalProperties.$mybus = mitt()

app.use(router)
app.use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) 
{
    app.component(key, component)
}

app.mount('#app')