<template>
    <div class="myfoot">
        <a target="_blank" :href=url_1><el-image :src="img_1"/></a>
        <a target="_blank" :href=url_2><el-image :src="img_2"/></a>
    </div>
</template>


<script>
export default {
    name: 'A_Foot',
    data() {
        return {
            url_1: "https://beian.miit.gov.cn/",
            url_2: "https://beian.mps.gov.cn/#/query/webSearch?code=37010402001455",
            img_1: require('../../assets/鲁ICP备.svg'),
            img_2: require('../../assets/鲁公网安备.svg')
        }
    }
}
</script>


<style scoped>
.myfoot {
    display: flex;
    align-items: center;
    justify-content: center;
}

a{
    margin: 2px;
}
</style>