<template>
    <!--############################### 表单界面 ###############################-->
    <el-form :model="form" ref="form" label-position="left">
        
        <!---------------------------- Select Time ---------------------------->
        <div>
            <el-icon><Star /></el-icon>
            <span>Select Time</span>
        </div>
        <el-row>
            <el-col :span="6">
                <el-form-item label="Year" prop="year">
                    <el-input-number v-model="form.year" :min="1997" :max="2029" step-strictly @change="change_index"/>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Doy" prop="doy">
                    <el-input-number v-model="form.doy" :min="1" :max="366" step-strictly />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Hour" prop="hour">
                    <el-input-number v-model="form.hour" :min="0" :max="24" :step="1" step-strictly />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item prop="time_flag" class="len1">
                    <el-radio-group v-model="form.time_flag">
                        <el-radio-button label="ut">UT</el-radio-button>
                        <el-radio-button label="lt">LT</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-row>

        <!---------------------------- Select Range ---------------------------->
        <div>
            <el-icon><Star /></el-icon>
            <span>Select Range</span>
        </div>
        <div>
            <el-col :span="6">
                <el-form-item prop="range" class="len2">
                    <el-radio-group v-model="form.range">
                        <el-radio-button label="station" @change="change_range">Station</el-radio-button>
                        <el-radio-button label="global"  @change="change_range">Global </el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-tooltip effect="dark" placement="top-start" content="Lat in [-90,90]">
                    <el-form-item label="Lat" prop="lat">
                        <el-input-number v-model="form.lat" :min="-90" :max="90" :step="2.5" :precision="1" :disabled="form.is_global" />
                    </el-form-item>
                </el-tooltip>
            </el-col>
            <el-col :span="6">
                <el-tooltip effect="dark" placement="top-start" content="Lon in [-180,180]">
                    <el-form-item label="Lon" prop="lon">
                        <el-input-number v-model="form.lon" :min="-180" :max="180" :step="5" :precision="1" :disabled="form.is_global" />
                    </el-form-item>
                </el-tooltip>
            </el-col>
        </div>

        <!---------------------------- Select index ---------------------------->
        <div>
            <el-icon><Star /></el-icon>
            <span>Select Index</span>
        </div>
        <div>
            <el-col :span="6">
                <el-form-item prop="way" class="len3">
                    <el-radio-group v-model="form.way">
                        <el-tooltip effect="dark" placement="top-start" content="In 1997-2022">
                            <el-radio-button label="read" border :disabled="form.year>2022">Read</el-radio-button>
                        </el-tooltip>
                        <el-radio-button label="input" border>Input</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="F10.7P" prop="f107">
                    <el-input-number v-model="form.f107" :precision="1" :min="0" :disabled="form.way === 'read'" />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Kp" prop="kp">
                    <el-input-number v-model="form.kp" :precision="1" :min="0" :max="5" :disabled="form.way === 'read'" />
                </el-form-item>
            </el-col>
        </div>

        <!---------------------------- Select Loop type ---------------------------->
        <div>
            <el-icon><Star /></el-icon>
            <span>Select Loop Type(Optional)</span>
        </div>
        <div>
            <el-col :span="6">
                <el-form-item prop="for_type" class="len5">
                    <el-select v-model="form.for_type" @change="change_fortype">
                        <el-option label="--Choose--" value="--choose--"></el-option>
                        <el-option label="Doy"  value="doy"  :disabled="form.is_global"></el-option>
                        <el-option label="Hour" value="hour" :disabled="form.is_global"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Start" prop="start">
                    <el-input-number v-if="form.for_type === '--choose--'" v-model="form.start" :min=1 :max=366 :stepsize="1" />
                    <el-input-number v-if="form.for_type === 'doy'"        v-model="form.start" :min=1 :max=366 :stepsize="1" />
                    <el-input-number v-if="form.for_type === 'hour'"       v-model="form.start" :min=0 :max=23  :stepsize="1" />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Stop" prop="stop">
                    <el-input-number v-if="form.for_type === '--choose--'" v-model="form.stop" :min=1 :max=366 :stepsize="1" />
                    <el-input-number v-if="form.for_type === 'doy'"        v-model="form.stop" :min=1 :max=366 :stepsize="1" />
                    <el-input-number v-if="form.for_type === 'hour'"       v-model="form.stop" :min=0 :max=23  :stepsize="1" />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="Step" prop="step_size">
                    <el-input-number v-if="form.for_type === '--choose--'" v-model="form.step_size" :min=1 :max=366 :stepsize="1" />
                    <el-input-number v-if="form.for_type === 'doy'"        v-model="form.step_size" :min=1 :max=366 :stepsize="1" />
                    <el-input-number v-if="form.for_type === 'hour'"       v-model="form.step_size" :min=0 :max=23  :stepsize="1" />
                </el-form-item>
            </el-col>

        </div>

        <!---------------------------- Select File Type ---------------------------->
        <div>
            <el-icon><Star /></el-icon>
            <span>Select File Type</span>
        </div>
        <div>
            <el-form-item label="" prop="file_type" class="len4">
                <el-radio-group v-model="form.file_type">
                    <el-radio-button label="mat" :disabled="!form.is_global">mat</el-radio-button>
                    <el-radio-button label="nc"  :disabled="!form.is_global">nc</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </div>

        <!---------------------------- Submit and Reset ---------------------------->
        <div>
            <el-form-item prop="status" class="operate">
                <el-button v-if="form.status === 0 || form.status === 2" v-model="form.status" @click="onSubmit">Submit</el-button>
                <el-button v-if="form.status === 1" v-model="form.status" type="primary" :loading="true">Calculating</el-button>
                <el-button v-if="form.status === 2 && form.is_global === true" v-model="form.status" type="success" @click="download">Download</el-button>
                <el-button @click="reset">Reset</el-button>
            </el-form-item>
        </div>
    </el-form>
</template>

  


<script>
export default {
    name: 'A_Form',
    data() {
        return {
            form: {
                year: 2022,
                doy: 1,
                time_flag: "ut",
                hour: 0,
                range: "station",
                is_global: false,
                lat: 0.0,
                lon: 0.0,
                way: "read",
                f107: 80.0,
                kp: 0,
                for_type: "--choose--",
                start: 1,
                stop: 365,
                step_size: 1,
                file_type: "mat",
                status: 0
            },
            img_name: "logo",
            TEC: null,
        }
    },
    methods: {
        change_range: function () {
            if (this.form.range === "station") {
                this.form.is_global = false
                this.form.file_type = "txt"
                this.form.status = 0
            } else {
                this.form.is_global = true
                this.form.for_type = "--choose--"
                this.form.file_type = "mat"
                this.form.status = 0
            }
        },
        change_index: function (){
            if (this.form.year > 2023){
                this.form.way = "input"
            } else {
                this.form.way = "read"
            }
        },
        change_fortype: function () {
            if (this.form.for_type === "doy") {
                this.form.start = 1
                this.form.stop = 365
                this.form.step_size = 1
            }
            if (this.form.for_type === "hour") {
                this.form.start = 0
                this.form.stop = 24
                this.form.step_size = 1
            }
        },
        onSubmit: function () {
            this.form.status = 1
            let info = JSON.parse(JSON.stringify(this.form))
            this.$http.post('/calTEC', info)
                .then((res) => {
                    console.log(res);
                    //if (this.form.range === 'station') {
                    //    this.TEC = res.data.TEC
                    //    this.$mybus.emit("TEC", this.TEC)
                    //} 
                    //else {
                    //   this.img_name = res.data.img_name
                    //   this.$mybus.emit("img_name", this.img_name)
                    //}
                    if (this.form.range === 'station') {
                        this.TEC = res.data.TEC
                        this.$mybus.emit("TEC", this.TEC)
                    }
                    this.form.status = res.data.status;
                })
                .catch((err) => console.log(err))
        },
        download: function () {
            let info = JSON.parse(JSON.stringify(this.form))
            this.$http.post('/download', info, { responseType: 'blob' })
                .then((res) => {
                    console.log(res)
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    let filename = "001." + this.form.file_type
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                }).catch((err) => console.log(err))
        },
        reset: function () {
            this.$refs['form'].resetFields();
            this.$mybus.emit("TEC", null);
            //this.$mybus.emit("img_name", "logo");
        }
    }
}
</script>
    


  
<style scoped>
div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.el-form>div:nth-child(even) {
    margin: 5px 0px 10px 16px;
}

.el-input-number {
    width: 120px;
    font-size: 14px;
}

.el-select {
    width: 164px;
    font-size: 14px;
    text-align: center;
    text-align-last: center;
}

.operate .el-button {
    margin: 0px 5px 0px 16px;
}
</style>

<style>
.len1 .el-radio-button__inner {
    padding: 8px 28px !important;
}

.len2 .el-radio-button__inner {
    padding: 8px 19px !important;
}

.len3 .el-radio-button__inner {
    padding: 8px 24px !important;
}

.len4 .el-radio-button__inner {
    padding: 8px 31px !important;
}
</style>