<template>
    <el-menu mode="horizontal"
             class="el-menu-demo"
             background-color="#545c64"
             text-color="#fff"
             active-text-color="#ffd04b"
             default-active="/"
             router>
        <el-menu-item index="/">
            <span><el-icon><House /></el-icon>Home</span>
        </el-menu-item>
        <el-menu-item index="team">
            <span><el-icon><User /></el-icon>Team</span>
        </el-menu-item>
        <el-menu-item index="achievement">
            <span><el-icon><Collection /></el-icon>Achievements</span>
        </el-menu-item>
    </el-menu>
</template>




<script>
export default {
    name: 'A_Nav',
}
</script>