import { createRouter, createWebHistory} from 'vue-router'
import Home from '../components/Home/Form/A_Home'
import Team from '../components/Home/Team/A_Team'
import Achievement from '../components/Home/Achievement/A_Achievement'


const instance = createRouter({
    history: createWebHistory(),
    routes : [
        {
            path: '/',
            component: Home
        },
        {
            path: '/team',
            component: Team
        },
        {
            path: '/achievement',
            component: Achievement
        }
    ]
  })


export default instance