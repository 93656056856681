<template>

        <!--#################### 导师 ####################-->
        <div>
            <el-card class="card" shadow="never">
                <a href="https://jgxy.sdut.edu.cn/2019/1106/c8888a355648/page.htm" target="_blank">
                    <img src="..\..\..\assets\冯建迪.png"  />
                    Jiandi Feng<br>(Prof.)
                </a>
            </el-card>
        </div>

        <!--#################### 学生-1 ####################-->
        <div>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\朱云聪.png" />
                <span>Yuncong Zhu<br>(2019)</span>
            </el-card>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\钟慧鑫.png" />
                <span>Huixin Zhong<br>(2019)</span>
            </el-card>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\张亭.png" />
                <span>Ting Zhang<br>(2020)</span>
            </el-card>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\黄睿婧.png" />
                <span>Ruijing Huang<br>(2020)</span>
            </el-card>
        </div>

        <!--#################### 学生-2 ####################-->
        <div>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\张一彬.png" />
                <span>Yibin Zhang<br>(2020)</span>
            </el-card>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\王开心.png"  />
                <span>Kaixin Wang<br>(2021)</span>
            </el-card>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\孟迪.png" />
                <span>Di Meng<br>(2022)</span>
            </el-card>
            <el-card class="card" shadow="never">
                <img src="..\..\..\assets\张志豪.png" />
                <span>Zhihao Zhang<br>(2023)</span>
            </el-card>
        </div>
        
</template>




<script>
export default {
    name: 'A_Team',
}
</script>




<style scoped>
div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.card {
    margin: 5px;
    padding: 0px;
    width: 160px;
    height: 220px;
    border: none;
}
img {
    display: block;
    height: 150px;
    object-fit: cover;
    border: 2px solid rgb(128, 128, 128);
}
a{
    text-decoration: none;
}
a, span{
    color: #363636;
    font-weight: bold;
    font-size: 1.0em;
}
a:hover{
    color: #337ecc;
}
</style>