<template>
  <div id="app">
    <el-container>

      <!--导航-->
      <el-header>
        <Nav />
      </el-header>

      <!--内容-->
      <el-main>
        <router-view />
      </el-main>

      <!--页脚-->
      <el-footer>
        <Foot />
      </el-footer>

    </el-container>
  </div>
</template>




<script>
import Nav from './components/Navbar/A_Nav'
import Foot from './components/Footer/A_Foot'

export default {
  name: 'App',
  components: {
    Nav,
    Foot
  }
}
</script>




<style>
* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #ffffff;
}

.el-header {
  padding: 0px !important;
}

.el-container{
  min-height: 100vh;
}

#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
               'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  text-align: center;
  --background-color-set--: #ffffff;
}
</style>