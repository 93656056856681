<template>
    <div class="set_margin">

        <!--#################### 主持项目 ####################-->
        <div style="margin: 10px">
            <div class="title">
                <el-icon color="#4b8dd2">
                    <Star />
                </el-icon>
                <span>主持项目</span>
            </div>
            <li v-for="(item, idx) in project" :key="idx">
                <span style="color:#ce7373">{{ idx + 1 }}.</span> {{ item.name }}
            </li>
        </div>

        <!--#################### 参与项目 ####################-->
        <div style="margin: 10px">
            <div class="title">
                <el-icon color="#4b8dd2">
                    <Star />
                </el-icon>
                <span>参与项目</span>
            </div>
            <li v-for="(item, idx) in project_in" :key="idx">
                <span style="color:#ce7373">{{ idx + 1 }}.</span> {{ item.name }}
            </li>
        </div>

        <!--#################### 学术论文 ####################-->
        <div style="margin: 10px">
            <div class="title">
                <el-icon color="#4b8dd2">
                    <Star />
                </el-icon>
                <span>学术论文</span>
            </div>
            <li v-for="(item, idx) in paper" :key="idx">
                <span style="color:#ce7373">[{{ idx + 1 }}] </span> {{ item.name }}
            </li>
        </div>

        <!--#################### 专著 ####################-->
        <div style="margin: 10px">
            <div class="title">
                <el-icon color="#4b8dd2">
                    <Star />
                </el-icon>
                <span>专著</span>
            </div>
            <li v-for="(item, idx) in monograph" :key="idx">
                <span style="color:#ce7373">{{ idx + 1 }}.</span> {{ item.name }}
            </li>
        </div>

    </div>
</template>




<script>
export default {
    name: 'A_Achievement',
    data() {
        return {
            project: [
                { name: "国家自然科学基金面上项目——基于规避精度不均匀性且分离(解)异常现象的时空组合构建电离层经验模型集合(42274040), 2023.01-2026.12" },
                { name: "国家自然科学基金青年项目——基于多源数据且顾及异常现象的全球电离层TEC经验模型建立方法研究(41804032), 2019.01-2021.12" },
                { name: "地理信息工程国家重点实验室开放基金课题——多源数据融合的中国低纬区域电离层经验模型构建(SKLGIE2021-M-3-2), 2022.10-2024.09" },
                { name: "大地测量与地球动力学国家重点实验室开放研究基金——中纬度夏季夜间异常区域电离层TEC经验模型的建立方法研究(SKLGED2019-3-4-E), 2019.01-2020.12" },
                { name: "上海市空间导航与定位技术重点实验室开放课题——基于CODE TEC的MSNA区域电离层经验模型研究(201920), 2019.06-2021.06" },
                { name: "地球空间环境与大地测量教育部重点实验室开放基金——基于VLF和GNSS的电离层—耀斑探测研究, 2018.01-2019.12" },
                { name: "空间天气学国家重点实验室开放课题——基于GPS-TEC(子午工程和IGS站)和VLF数据的电离层太阳耀斑探测研究, 2021.01-2022.12" },
                { name: "空间天气学国家重点实验室开放课题——中纬度夏季夜间异常区域电离层TEC经验模型的建立方法研究, 2019.01-2020.12" }
            ],
            project_in: [
                { name: "国家自然科学基金面上项目——基于SWARM星群的地球重力场模型建立及其在地球动力学中的应用研究(41474018), 2015.01-2018.12" },
                { name: "国家自然科学基金面上项目——μm级GEO/GNSS-LEO高低卫星跟踪模式确定地表浅层物质迁移的关键技术研究(41774019), 2018.01-2021.12" }
            ],
            paper: [
                { name: "Feng J, Zhang Y, Li W, et al. Analysis of ionospheric TEC response to solar and geomagnetic activities at different solar activity stages. Advances in Space Research, 2023, 71(5): 2225-2239." },
                { name: "Feng J, Yuan Y, Zhang T, et al. Analysis of Ionospheric Anomalies before the Tonga Volcanic Eruption on 15 January 2022. Remote Sensing, 2023, 15(19): 4879." },
                { name: "Feng J, Wang K, Li W, et al. Analysis of temporal and spatial variation characteristics of midlatitude summer nighttime anomaly in low and middle solar activity period. Advances in Space Research, 2023, 71(10): 4351-4360." },
                { name: "Feng J, Zhang T, Li W, et al. A new global TEC empirical model based on fusing multi-source data. GPS Solutions, 2022, 27(1): 20." },
                { name: "Feng J, Zhu Y, Zhang T, et al. Solar activity influence on the temporal and spatial variations of the Arctic and Antarctic ionosphere. Advances in Space Research, 2022, 70(1): 188-202." },
                { name: "Feng J, Zhao Z, Han B, et al. A Single-Station Empirical TEC Model Suitable for MSNA Area: Taking ohi3 Station as an Example. Geomatics and Information Science of Wuhan University, 2021, 46(2): 270-279,288." },
                { name: "Feng J, Zhang T, Han B, et al. Analysis of spatiotemporal characteristics of internal coincidence accuracy in global TEC grid data. Advances in Space Research, 2021, 68(8): 3365-3380." },
                { name: "Feng J, Han B, Gao F, et al. Analysis of Global Ionospheric Response to Solar Flares Based on Total Electron Content and Very Low Frequency Signals. IEEE Access, 2021, 9: 57618-57631." },
                { name: "Zhao Z, Feng J. Spatio-temporal analysis of land use changes using remote sensing in Horqin sandy land, China[J]. Sensor Review, 2019, 39: 844-856." },
                { name: "Feng J, Han B, Zhao Z, et al. A New Global Total Electron Content Empirical Model. Remote Sensing, 2019, 11(6): 706." },
                { name: "Feng J. Research and implementation of empirical TEC models. Acta Geodaetica et Cartographica Sinica, 2019, 48(10): 1339." },
                { name: "Zhao Z, Feng J, Han B, et al. A single-station empirical TEC model based on long-time recorded GPS data for estimating ionospheric delay. J. Space Weather Space Clim., 2018, 8: A59." },
                { name: "Feng J, Wang Z, Jiang W, et al. A single-station empirical model for TEC over the Antarctic Peninsula using GPS-TEC data. Radio Science, 2017, 52(2): 196-214." },
                { name: "Feng J, Jiang W, Wang Z, et al. Regional TEC model under quiet geomagnetic conditions and low-to-moderate solar activity based on CODE GIMs. Journal of Atmospheric and Solar-Terrestrial Physics, 2017, 161: 88-97." },
                { name: "Feng J, Wang Z, Jiang W, et al. A new regional total electron content empirical model in northeast China. Advances in Space Research, 2016, 58(7): 1155-1167." },
            ],
            monograph: [
                { name: "冯建迪. 电离层时变特性分析及其经验模型建立方法研究[M]. 冶金工业出版社, 2020." }
            ]
        }
    }
}
</script>




<style scoped>
.set_margin{
    margin: 10px 10px;
}
li {
    padding-left: 2em;
    text-align: left;
    list-style: none;
    line-height: 30px;
    font-size: 14px;
}
span {
    color: #4b8dd2;
}
.title {
    display: flex;
    align-items: center;
    justify-content: left;
}
</style>