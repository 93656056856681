<template>
  <el-table :data="TEC" style="width:80%" stripe border>
    <el-table-column prop="time" label="Time" align="center"/>
    <el-table-column prop="tec" label="TEC" align="center"/>
  </el-table>
</template>




<script>
export default {
    name: 'A_TEC',
    data(){
      return{
        TEC: null
      }
    },
    created(){
      this.$mybus.on('TEC', (res) => {
        this.TEC = res
      })
    }
}
</script>



   
<style scoped>
.el-table{
   margin: 30px 20px;
}
</style>