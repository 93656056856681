<template>
    <el-container>
        <!--内容-->
        <el-main>
            <Form />
        </el-main>

        <!--侧栏-->
        <el-aside>
            <TEC />
        </el-aside>

    </el-container>
</template>
  
  
  
  
<script>
import Form from './A_Form'
import TEC from './A_TEC'

export default {
    name: 'A_Home',
    components: {
        Form,
        TEC,
    }
}
</script>
  
  
  
  
<style scoped>
.el-main {
    padding: 10px !important;
}

.el-aside {
    border-left: 1px solid #e0e0e0;
}
</style>
  