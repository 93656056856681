import axios from 'axios'


const instance = axios.create({   
    //baseURL: '/api' ,
    baseURL: 'https://model.happysimple.wang/api',
    timeout: 10000, 
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    }
});


export default instance;